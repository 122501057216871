


























































































import LandingSection from '@/components/LandingSection.vue'
import PatternWaterDrop from '@/components/PatternWaterDrop.vue'
import { computed, defineComponent, ref } from '@vue/composition-api'
import ServiceModal from './ServiceModal.vue'
import BaseBox from './_base/BaseBox.vue'

interface ServiceConfig {
  id: string
  title: string
  description: string
  advantages: string[]
  width: string
  position: ServiceConfigPosition
  textWidth: string
  textPosition: ServiceConfigPosition
  imageSrc: string
  isDisabled?: boolean
}

interface ServiceConfigPosition {
  top?: string
  right?: string
  bottom?: string
  left?: string
}

export default defineComponent({
  name: 'LandingSectionServices',

  components: {
    LandingSection,
    PatternWaterDrop,
    ServiceModal,
    BaseBox,
  },

  setup() {
    const servicesConfig: ServiceConfig[] = [
      {
        id: 'water-softener',
        title: 'servicesMap.waterSoftener.title',
        description: 'servicesMap.waterSoftener.description',
        advantages: [
          'servicesMap.waterSoftener.advantages.0',
          'servicesMap.waterSoftener.advantages.1',
          'servicesMap.waterSoftener.advantages.2',
          'servicesMap.waterSoftener.advantages.3',
        ],
        width: '10%',
        position: {
          top: '16.4%',
          left: '9.5%',
        },
        textWidth: '90%',
        textPosition: {
          top: '80%',
          left: '52%',
        },
        imageSrc: '@/assets/images/services/water-softener.jpg',
      },
      {
        id: 'desalination-plant',
        title: 'servicesMap.desalinationPlant.title',
        description: 'servicesMap.desalinationPlant.description',
        advantages: [
          'servicesMap.desalinationPlant.advantages.0',
          'servicesMap.desalinationPlant.advantages.1',
          'servicesMap.desalinationPlant.advantages.2',
          'servicesMap.desalinationPlant.advantages.3',
          'servicesMap.desalinationPlant.advantages.4',
          'servicesMap.desalinationPlant.advantages.5',
        ],
        width: '19.4%',
        position: {
          top: '49.5%',
          left: '18.7%',
        },
        textWidth: '48%',
        textPosition: {
          top: '74.2%',
          left: '77%',
        },
        imageSrc: '@/assets/images/services/desalination-plant.jpg',
      },
      {
        id: 'treatment-wetlands',
        title: 'servicesMap.treatmentWetlands.title',
        description: 'servicesMap.treatmentWetlands.description',
        advantages: [
          'servicesMap.treatmentWetlands.advantages.0',
          'servicesMap.treatmentWetlands.advantages.1',
          'servicesMap.treatmentWetlands.advantages.2',
          'servicesMap.treatmentWetlands.advantages.3',
          'servicesMap.treatmentWetlands.advantages.4',
          'servicesMap.treatmentWetlands.advantages.5',
          'servicesMap.treatmentWetlands.advantages.6',
        ],
        width: '20%',
        position: {
          top: '61.7%',
          left: '46.5%',
        },
        textWidth: '50%',
        textPosition: {
          top: '58.5%',
          left: '54.7%',
        },
        imageSrc: '@/assets/images/services/treatment-wetlands.jpg',
      },
      {
        id: 'lagoon-systems',
        title: 'servicesMap.lagoonSystems.title',
        description: 'servicesMap.lagoonSystems.description',
        advantages: [
          'servicesMap.lagoonSystems.advantages.0',
          'servicesMap.lagoonSystems.advantages.1',
          'servicesMap.lagoonSystems.advantages.2',
          'servicesMap.lagoonSystems.advantages.3',
          'servicesMap.lagoonSystems.advantages.4',
          'servicesMap.lagoonSystems.advantages.5',
          'servicesMap.lagoonSystems.advantages.6',
        ],
        width: '23%',
        position: {
          top: '25.5%',
          left: '47.8%',
        },
        textWidth: '40%',
        textPosition: {
          top: '76%',
          left: '70.5%',
        },
        imageSrc: '@/assets/images/services/lagoon-system.jpg',
      },
      {
        id: 'inverse-osmosis',
        title: 'servicesMap.inverseOsmosis.title',
        description: 'servicesMap.inverseOsmosis.description',
        advantages: [
          'servicesMap.inverseOsmosis.advantages.0',
          'servicesMap.inverseOsmosis.advantages.1',
          'servicesMap.inverseOsmosis.advantages.2',
          'servicesMap.inverseOsmosis.advantages.3',
          'servicesMap.inverseOsmosis.advantages.4',
          'servicesMap.inverseOsmosis.advantages.5',
          'servicesMap.inverseOsmosis.advantages.6',
          'servicesMap.inverseOsmosis.advantages.7',
        ],
        width: '10.5%',
        position: {
          top: '14%',
          left: '71.5%',
        },
        textWidth: '70%',
        textPosition: {
          top: '76%',
          left: '47.5%',
        },
        imageSrc: '@/assets/images/services/inverse-osmosis.jpg',
      },
      {
        id: 'anaerobic-bioreactor',
        title: 'servicesMap.anaerobicBioreactor.title',
        description: 'servicesMap.anaerobicBioreactor.description',
        advantages: [
          'servicesMap.anaerobicBioreactor.advantages.0',
          'servicesMap.anaerobicBioreactor.advantages.1',
          'servicesMap.anaerobicBioreactor.advantages.2',
          'servicesMap.anaerobicBioreactor.advantages.3',
          'servicesMap.anaerobicBioreactor.advantages.4',
          'servicesMap.anaerobicBioreactor.advantages.5',
          'servicesMap.anaerobicBioreactor.advantages.6',
          'servicesMap.anaerobicBioreactor.advantages.7',
        ],
        width: '12.5%',
        position: {
          top: '-8.5%',
          left: '36.4%',
        },
        textWidth: '87%',
        textPosition: {
          top: '90%',
          left: '70%',
        },
        imageSrc: '@/assets/images/services/anaerobic-bioreactor.jpg',
      },
      {
        id: 'water-treatment-plant',
        title: 'servicesMap.waterTreatmentPlant.title',
        description: 'servicesMap.waterTreatmentPlant.description',
        advantages: [
          'servicesMap.waterTreatmentPlant.advantages.0',
          'servicesMap.waterTreatmentPlant.advantages.1',
          'servicesMap.waterTreatmentPlant.advantages.2',
          'servicesMap.waterTreatmentPlant.advantages.3',
          'servicesMap.waterTreatmentPlant.advantages.4',
        ],
        width: '25%',
        position: {
          top: '14%',
          left: '19.5%',
        },
        textWidth: '45%',
        textPosition: {
          top: '76.8%',
          left: '61%',
        },
        imageSrc: '@/assets/images/services/water-treatment-plant.jpg',
      },
      {
        id: 'pool-and-bio-pool',
        title: 'servicesMap.poolAndBioPool.title',
        description: 'servicesMap.poolAndBioPool.description',
        advantages: [
          'servicesMap.poolAndBioPool.advantages.0',
          'servicesMap.poolAndBioPool.advantages.1',
          'servicesMap.poolAndBioPool.advantages.2',
          'servicesMap.poolAndBioPool.advantages.3',
        ],
        width: '23%',
        position: {
          top: '36%',
          left: '34.8%',
        },
        textWidth: '37.5%',
        textPosition: {
          top: '68%',
          left: '61%',
        },
        imageSrc: '@/assets/images/services/pool-and-bio-pool.jpg',
      },
      {
        id: 'mbbr',
        title: 'servicesMap.mbbr.title',
        description: 'servicesMap.mbbr.description',
        advantages: [
          'servicesMap.mbbr.advantages.0',
          'servicesMap.mbbr.advantages.1',
          'servicesMap.mbbr.advantages.2',
          'servicesMap.mbbr.advantages.3',
          'servicesMap.mbbr.advantages.4',
          'servicesMap.mbbr.advantages.5',
          'servicesMap.mbbr.advantages.6',
          'servicesMap.mbbr.advantages.7',
          'servicesMap.mbbr.advantages.8',
        ],
        width: '12.5%',
        position: {
          top: '-1%',
          left: '56.4%',
        },
        textWidth: '50%',
        textPosition: {
          top: '86%',
          left: '70%',
        },
        imageSrc: '@/assets/images/services/mbbr.jpg',
      },
      {
        id: 'sed-logo',
        title: '',
        description: '',
        advantages: [],
        width: '28%',
        position: {
          top: '41%',
          left: '67%',
        },
        textWidth: '50%',
        textPosition: {
          top: '86%',
          left: '70%',
        },
        imageSrc: '',
        isDisabled: true,
      },
    ]

    const services = computed(() =>
      servicesConfig.map((config) => ({
        id: config.id,
        container: {
          style: {
            position: 'absolute',
            width: config.width,
            ...config.position,
          },
        },
        image: {
          path: `@/assets/images/services/${config.id}.svg`,
        },
        text: config.isDisabled
          ? null
          : {
              path: `@/assets/images/services/${config.id}-text.svg`,
              style: {
                position: 'absolute',
                width: config.textWidth,
                ...config.textPosition,
              },
            },
        config,
      }))
    )

    const selectedServiceConfig = ref<ServiceConfig | null>(null)

    return {
      selectedServiceConfig,
      services,
    }
  },
})
