

























































































import CurveWaterWaves from '@/components/CurveWaterWaves.vue'
import i18n from '@/setup/i18n'
import { computed, defineComponent, PropType } from '@vue/composition-api'

export default defineComponent({
  name: 'ServiceModal',

  components: {
    CurveWaterWaves,
  },

  props: {
    id: {
      type: String,
      required: true,
    },

    title: {
      type: String,
      required: true,
    },

    description: {
      type: String,
      required: true,
    },

    advantages: {
      type: Array as PropType<string[]>,
      required: true,
    },

    imageSrc: {
      type: String,
      required: true,
    },
  },

  emits: {
    close: null,
  },

  setup(props, context) {
    const ariaLabelledBy = computed(() => i18n.t(props.title))
    const ariaDescribedBy = computed(() => i18n.t(props.description))

    function close(): void {
      context.emit('close')
    }

    return {
      ariaLabelledBy,
      ariaDescribedBy,
      close,
    }
  },
})
